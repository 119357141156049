import React from 'react'
// import {Link} from 'react-router-dom'
import './Styles.css'
import Img1 from './Img1.jpg'


function Home() {
  return (
    <div>

        
        <div className='bannercont'> 

                <div className='bannerhome'>
                  <p className='headhome'>  Make your B2B Growth Journey easier </p> </div>
                  <div className=''>  </div>
                  <div className='bannerimgcont'> <img className='bannerimg' src={Img1} alt="" /> </div>
                 

                {/* <div className='bannerhome'>  Make B2B Business easier </div> */}

                {/* <div className='bannerhome'>  Make B2B Business easier </div> */}

       </div>



       
        <div className='startnowBtCont'> 
        <div className='startnowBt'> <a href="https://docs.google.com/forms/d/1uf1Icfv04v2zS9tloKLoS6wcfQMKrsHRYgWdzYu5tTA/edit" >
          Apply now  </a> </div> 

          </div>
       


        
    </div>
  )
}

export default Home